<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="oemCustomer.name"
        dense
        :label="$t('name_*')"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col
      cols="6"
      sm="6"
    >
      <v-text-field
        v-model="oemCustomer.email"
        dense
        :label="$t('email_*')"
        :rules="[rules.required, rules.email]"
      />
    </v-col>
    <v-col
      cols="6"
      sm="6"
    >
      <v-select
        v-model="oemCustomer.device_type"
        dense
        :items="deviceTypeLists"
        item-value="id"
        item-text="name"
        :label="$t('device_type_*')"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col
      v-if="isOsTypeIos"
      cols="6"
      sm="6"
    >
      <v-text-field
        v-model="oemCustomer.apple_email"
        dense
        :label="$t('apple_email_*')"
        :rules="[rules.required, rules.email]"
      />
    </v-col>
    <v-col
      cols="6"
      sm="6"
    >
      <v-text-field
        v-model="oemCustomer.date_of_birth"
        dense
        :label="$t('date_of_birth_*')"
        type="date"
        :max="getCurrentDate()"
        :rules="[rules.required, validateDateOfBirth]"
      />
    </v-col>
    <v-col
      cols="6"
      sm="6"
    >
      <v-text-field
        v-model="oemCustomer.address"
        dense
        :label="$t('address')"
      />
    </v-col>

    <v-col
      cols="6"
      sm="6"
    >
      <v-select
        v-model="oemCustomer.gender"
        dense
        :items="genderLists"
        item-value="id"
        item-text="name"
        :label="$t('gender_*')"
        :rules="[rules.required]"
      />
    </v-col>

    <v-col
      cols="6"
      sm="6"
    >
      <v-text-field
        v-model="oemCustomer.cnic"
        dense
        :label="$t('cnic_*')"
        type="number"
        :rules="[rules.required, rules.min_length(13), rules.max_length(13)]"
      />
    </v-col>
    <v-col
      cols="6"
      sm="6"
    >
      <v-select
        v-model="oemCustomer.country_code"
        dense
        :items="countryCodeLists"
        item-value="id"
        item-text="name"
        :label="$t('country_code_*')"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col
      cols="6"
      sm="6"
    >
      <v-text-field
        v-model="oemCustomer.phone_number"
        dense
        type="number"
        :label="$t('phone_number_*')"
        :rules="[rules.required, rules.min_length(8), rules.max_length(11)]"
      />
    </v-col>
    <!-- <v-col
      cols="6"
      sm="6"
    >
      <v-select
        v-model="oemCustomer.is_active"
        dense
        :items="statusLists"
        item-value="id"
        item-text="name"
        :label="$t('status_*')"
        :rules="[rules.required]"
      />
    </v-col> -->
    <v-col
      cols="12"
      sm="6"
    >
      <v-select
        v-model="oemCustomer.user_type"
        dense
        :items="userTypeLists"
        item-value="id"
        item-text="name"
        :label="$t('user_type_*')"
        :rules="[rules.required]"
      />
    </v-col>
    <v-col
      v-if="oemCustomer.user_type == 'business'"
      cols="12"
      sm="6"
    >
      <v-select
        v-model="oemCustomer.oem_b2b_id"
        dense
        :items="oemB2bList"
        item-value="id"
        item-text="business_name"
        clearable
        :label="$t('businesses')"
      />
    </v-col>
    <!-- <v-col
      cols="12"
      sm="6"
    >
      <v-checkbox
        v-model="oemCustomer.test_customer"
        label="Test Customer"
         hide-details
        :ripple="false"
        :true-value="1"
        :false-value="0"
      />
    </v-col> -->
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
export default {
  mixins: [validationRules],
  data() {
    return {
      oemCustomer: {},
      // statusLists: [
      //   {
      //     id: "1",
      //     name: "Active",
      //   },
      //   {
      //     id: "0",
      //     name: "Inactive",
      //   },
      // ],
      genderLists: [
        {
          id: "male",
          name: "Male",
        },
        {
          id: "female",
          name: "Female",
        },
      ],
      userTypeLists: [
        {
          id: "individual",
          name: "Individual",
        },
        // {
        //   id: "business",
        //   name: "Business",
        // },
        { id: "internal", name: "Internal" },
      ],
      deviceTypeLists: [
        {
          id: "android",
          name: "Android",
        },
        {
          id: "ios",
          name: "IOS",
        },
      ],
      countryCodeLists: [
        {
          id: "+92",
          name: "+92",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      oemB2bList: "oemBusiness/getOEMB2BList",
    }),
    isOsTypeIos() {
      return this.oemCustomer.device_type == "ios";
    },
  },
  watch: {
    "oemCustomer.device_type": {
      handler: async function (value) {
        if (value === "android") {
          this.oemCustomer.apple_email = null;
        }
      },
    },
    "oemCustomer.user_type": {
      handler: async function () {
        this.oemCustomer.oem_b2b_id = undefined;
      },
    },
    oemCustomer: {
      handler: async function (value) {
        this.changeOemCustomer(value);
      },
    },
  },
  methods: {
    changeOemCustomer(data) {
      data.is_active = true; // Hardcoded for Vehicle Creation
      this.$emit("customerDetail", data);
    },
  },
};
</script>
